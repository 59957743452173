<template>
  <v-container
    id="client-forms"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row>
      <v-col
        v-if="!loading"
        cols="12"
        md="12"
      >
        <v-row justify="space-between">
          <v-col
            v-if="!loading"
            cols="auto"
            class="mr-auto mt-0 mb-0 pt-0 pb-0"
          >
            <v-breadcrumbs
              class="mt-0 mb-0 pt-0 pb-0"
              :items="[{text: 'Ваш центр', to: '/'}, {text: 'Клиенты', to: '/clients'}, {text: clientNameBreadcrumb()}]"
              large
            />
          </v-col>
          <v-spacer />
          <v-col
            v-if="!loading && (prevClientId || nextClientId)"
            cols="auto"
            class="mr-auto mt-0 mb-0 pt-0 pb-0"
          >
            <v-btn
              v-if="prevClientId"
              color="primary"
              small
              @click="singleClient(prevClientId)"
            >
              <v-icon>mdi-chevron-left</v-icon>
              {{ prevClientName }}
            </v-btn>
            <v-btn
              small
            >
              {{ clientName(client) }}
            </v-btn>
            <v-btn
              v-if="nextClientId"
              color="primary"
              small
              @click="singleClient(nextClientId)"
            >
              {{ nextClientName }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          v-if="!loading"
          color="success"
          icon="mdi-badge-account"
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <v-tooltip
              right
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="card-title font-weight-light"
                  v-on="on"
                >
                  Данные клиента
                  <v-icon
                    class="help-pointer"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </div>
              </template>
              <span>Карточка содержит полную информацию о клиенте, его абонементах, оплатах, остатке на счете. Если счет клиента стал положительным из-за возврата услуг (абонементов), то эти средства можно использовать для оплаты другого абонемента или вывести со счёта и вернуть клиенту - для этого нажмите "Сделать возврат". Не забывайте менять статус клиента!</span>
            </v-tooltip>
          </template>
          <base-material-alert
            v-if="!client.status"
            color="warning"
            dark
            icon="mdi-zip-disk"
          >
            Архивный клиент!
          </base-material-alert>
          <v-form
            ref="form"
            class="mt-5"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="client.state"
                  label="Статус"
                  :items="states"
                  :error-messages="fieldError('state')"
                  item-value="tag"
                  item-text="name"
                  @change="onStateChange"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="client.result"
                  :error-messages="fieldError('result')"
                  label="Причина отказа"
                  :items="results"
                  item-value="tag"
                  item-text="name"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="client.channel"
                  :error-messages="fieldError('channel')"
                  label="Канал"
                  :items="channels"
                  item-value="tag"
                  item-text="name"
                />
              </v-col>
            </v-row>
            <v-row v-if="showTrialDate">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  readonly
                  :value="formatDate(client.trial_date)"
                  label="Дата пробного занятия"
                  :error-messages="fieldError('trial_date')"
                  color="secondary"
                  prepend-icon="mdi-calendar-outline"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="client.parents"
                  required
                  label="ФИО родителя/опекуна"
                  :error-messages="fieldError('parents')"
                />
              </v-col>
              <v-col
                cols="11"
                md="3"
              >
                <v-text-field
                  v-model="client.phone"
                  label="Телефон"
                  :error-messages="fieldError('phone')"
                  required
                />
              </v-col>
              <v-col
                cols="1"
                md="1"
              >
                <v-icon
                  v-if="client.phone"
                  title="Отправить клиенту приглашение и код для доступа в ЛК"
                  class="mr-0 pr-0"
                  @click="confirmInviteDialog = true"
                >
                  mdi-card-account-mail
                </v-icon>
              </v-col>
              <v-col
                cols="11"
                md="3"
              >
                <v-text-field
                  v-model="client.email"
                  :error-messages="fieldError('email')"
                  label="E-mail"
                />
              </v-col>
              <v-col
                cols="1"
                md="1"
              >
                <v-icon
                  v-if="client.email"
                  title="Отправить клиенту приглашение и код для доступа в ЛК"
                  class="mr-0 pr-0"
                  @click="confirmInviteDialog = true"
                >
                  mdi-card-account-mail
                </v-icon>
              </v-col>
            </v-row>
            <v-textarea
              v-model="client.comment"
              :error-messages="fieldError('comment')"
              rows="1"
              auto-grow
              label="Комментарий"
            />
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="client.name"
                  label="ФИО ребенка"
                  :error-messages="fieldError('name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  ref="menu2"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  min-width="290px"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      :value="formatDate(client.birthday)"
                      label="День рождения ребенка"
                      :error-messages="fieldError('birthday')"
                      color="secondary"
                      prepend-icon="mdi-calendar-outline"
                      :messages="ageHint"
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="client.birthday"
                    color="secondary"
                    locale="ru-ru"
                    landscape
                    scrollable
                    first-day-of-week="1"
                    @input="menu3 = false"
                  >
                    <v-spacer />

                    <v-btn
                      color="secondary"
                      large
                      @click="menu3 = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="client.dogovor"
                  :error-messages="fieldError('dogovor')"
                  label="Номер договора"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-model="client.address"
                  :error-messages="fieldError('address')"
                  label="Адрес проживания"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="client.teacher_id"
                  :error-messages="fieldError('teacher_id')"
                  label="Закрепленный педагог"
                  item-value="id"
                  item-text="name"
                  :items="teachers"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-select
                  v-model="client.timetableIds"
                  :error-messages="fieldError('timetableIds')"
                  color="secondary"
                  item-color="secondary"
                  label="Группы по расписанию"
                  item-value="id"
                  item-text="name"
                  multiple
                  :items="timetables"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-2 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          item-value="item.id"
                          return-object
                          v-text="item.name"
                        />
                      </v-list-item-content>

                      <v-scale-transition>
                        <v-list-item-icon
                          v-if="attrs.inputValue"
                          class="my-3"
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-icon>
                      </v-scale-transition>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="client.course_id"
                  :error-messages="fieldError('course_id')"
                  label="Интересующий курс"
                  item-value="id"
                  item-text="name"
                  :items="courses"
                />
              </v-col>
            </v-row>
          </v-form>

          <v-card-actions class="pl-0">
            <v-btn
              color="success"
              min-width="100"
              :disabled="loading"
              @click="update()"
            >
              Сохранить
            </v-btn>
            <v-btn
              v-if="client.status"
              color="error"
              min-width="100"
              title="Переместить клиента в архив"
              :disabled="loading"
              @click="archive()"
            >
              В архив
            </v-btn>
            <v-btn
              v-if="!client.status"
              color="error"
              min-width="100"
              :disabled="loading"
              @click="unarchive()"
            >
              Вернуть из архива
            </v-btn>
            <v-btn
              v-if="!homeTeacherAdmin() && client.status && client.state != 'awaiting_trial'"
              color="info"
              min-width="100"
              @click="probDialog = true"
            >
              Назначить пробное
            </v-btn>
            <v-btn
              v-if="client.status && client.timetableIds && client.timetableIds.length > 0"
              color="teal"
              min-width="100"
              @click="moveDialog = true"
            >
              Сменить группу
            </v-btn>
          </v-card-actions>
        </base-material-card>

        <base-material-card
          v-if="!loading"
          color="teal"
          icon="mdi-list-status"
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div
              class="card-title font-weight-light"
            >
              <v-row>
                <v-col>Задачи</v-col>
                <v-col>
                  <v-switch
                    v-model="showArchived"
                    class="mt-0 mb-0"
                    label="Показать архивные"
                  />
                </v-col>
                <v-col>
                  <v-switch
                    v-model="showCompleted"
                    class="mt-0 mb-0"
                    label="Показать выполненные"
                  />
                </v-col>
              </v-row>
            </div>
          </template>

          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="tasksHeaders"
            :items="tasksFiltered"
            :sort-by="['date', 'time']"
            :sort-desc="[false, false]"
            @click:row="singleTask"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ archived: item.status === 0, completed: item.completed === 1 }"
                  @click="singleTask(item)"
                >
                  <td>{{ item.id }}</td>
                  <td>
                    <span>{{ item.action }}</span>
                    <span class="d-block text--disabled">{{ item.comment }}</span>
                  </td>
                  <td>
                    <v-chip
                      v-if="item.date"
                      :color="getActionStateColor(item)"
                    >
                      {{ formatActionDate(item.date, item.time) }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>

          <v-card-actions class="pl-0">
            <v-btn
              color="secondary"
              min-width="100"
              :disabled="loading"
              @click="createAction()"
            >
              Создать задачу
            </v-btn>
          </v-card-actions>
        </base-material-card>

        <base-material-card
          v-if="!loading"
          color="orange"
          icon="mdi-account-convert"
          title="История статусов"
          class="px-5 py-3"
        >
          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="stateLogsHeaders"
            :items="client.stateLogs"
          >
            <template v-slot:item.created="{ item }">
              {{ item.created | formatDateFromUnixTime }}
            </template>
            <template v-slot:item.oldstate="{ item }">
              <span v-if="item.oldStateRel">{{ item.oldStateRel.name }}</span>
              <span v-else>&mdash;</span>
            </template>
            <template v-slot:item.newstate="{ item }">
              <span v-if="item.newStateRel">{{ item.newStateRel.name }}</span>
              <span v-else>&mdash;</span>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <base-material-card
          v-if="!loading"
          color="indigo"
          icon="mdi-cash"
          title="Абонементы"
          class="px-5 py-3"
        >
          <v-data-table
            :loading="loading"
            loading-text="Загрузка абонеметов..."
            :headers="subsHeaders"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="client.subscriptions"
            @click:row="singleSubscription"
          >
            <template v-slot:item.timetable_id="{ item }">
              <a
                v-if="item.timetable"
                :href="`/timetable/${item.timetable_id}`"
                title="Открыть группу в новой вкладке"
                target="_blank"
                @click.stop=""
              >
                <v-chip
                  class="break-chip"
                  color="pink lighten-5"
                  x-small
                >
                  {{ item.timetable.name }}<v-icon right>mdi-open-in-new</v-icon>
                </v-chip>
              </a>
              <v-chip
                v-else
                class="break-chip"
                color="pink lighten-5"
                x-small
                title="Группа указанная в данном абонементе была удалена"
              >
                Удаленная группа #{{ item.timetable_id }}
              </v-chip>
            </template>
            <template v-slot:item.start_date="{ item }">
              {{ item.start_date | formatDateFilter }}
            </template>
            <template v-slot:item.end_date="{ item }">
              {{ item.end_date | formatDateFilter }}
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip
                title="Перейти к абонементу"
                class="break-chip"
                x-small
                :color="getStatusColor(item)"
              >
                {{ getStatusText(item) }}
              </v-chip>
            </template>
          </v-data-table>

          <v-card-actions class="pl-0">
            <v-btn
              color="info"
              min-width="100"
              :disabled="loading"
              @click="createSubscription()"
            >
              Создать абонемент
            </v-btn>
            <v-btn
              color="teal"
              min-width="100"
              target="_blank"
              :href="toSubscriptionsList()"
            >
              Показать на календаре
            </v-btn>
          </v-card-actions>
        </base-material-card>

        <base-material-card
          v-if="!loading"
          color="primary"
          icon="mdi-wallet"
          title="Платежи и списания"
          class="px-5 py-3"
        >
          <base-material-alert
            color="warning"
            dark
            icon="mdi-alert-box"
            class="mb-0"
          >
            <strong>Счет*:</strong>
            <v-chip
              color="red"
              class="ml-2"
            >
              {{ client.balance }}
            </v-chip>
            <v-divider class="my-4" />
            <p class="mb-0">
              * Отрицательные значения свидетельствуют о задолженности, положительные &mdash; о переплате.
            </p>
          </base-material-alert>
          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="paymentsHeaders"
            :items="client.paymentsAndBills"
            :sort-by="['id']"
            :sort-desc="[true]"
            @click:row="singleSubscriptionByPayment"
          >
            <template v-slot:item.subscription_id="{ item }">
              #{{ item.subscription_id }}
            </template>
            <template v-slot:item.created_date="{ item }">
              {{ item.created_date | formatDateFromUnixTime }}
            </template>
          </v-data-table>

          <p class="body-2 text--disabled pt-4">
            Для возврата денег выберите абонемент и оформите возврат на карточке конкретного абонемента.
          </p>

          <!-- <v-card-actions class="pl-0">
            <v-btn
              color="error"
              min-width="100"
              @click="createRefund()"
            >
              Сделать возврат
            </v-btn>
          </v-card-actions> -->
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="subscriptionDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Абонемент {{ client.name }}

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="subscriptionDialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1 text-center">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                ref="form"
                class="mt-5"
                lazy-validation
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-select
                      v-model="selectedTemplate"
                      label="Шаблон абонемента"
                      :items="templates"
                      item-value="id"
                      item-text="name"
                      return-object
                      @change="onTemplateChange($event)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="subscription.price"
                      required
                      label="Стоимость *"
                      :error-messages="fieldError('price')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="subscription.lessons_count"
                      required
                      label="Число занятий"
                      :error-messages="fieldError('lessons_count')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-menu
                      ref="startDateMenu"
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="startDate"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          :value="formatDate(subscription.start_date)"
                          label="с *"
                          :error-messages="fieldError('start_date')"
                          color="secondary"
                          prepend-icon="mdi-calendar-outline"
                          messages="Дата начала действия абонемента"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        v-model="subscription.start_date"
                        color="secondary"
                        locale="ru-ru"
                        landscape
                        first-day-of-week="1"
                        scrollable
                        @input="startDateMenu = false"
                      >
                        <v-spacer />

                        <v-btn
                          color="secondary"
                          large
                          @click="startDateMenu = false"
                        >
                          Cancel
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-menu
                      ref="endDateMenu"
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="endDate"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          :value="formatDate(subscription.end_date)"
                          label="по *"
                          :error-messages="fieldError('end_date')"
                          color="secondary"
                          prepend-icon="mdi-calendar-outline"
                          messages="Дата окончания действия абонемента (включительно)"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        v-model="subscription.end_date"
                        color="secondary"
                        locale="ru-ru"
                        landscape
                        scrollable
                        first-day-of-week="1"
                        @input="endDateMenu = false"
                      >
                        <v-spacer />

                        <v-btn
                          color="secondary"
                          large
                          @click="endDateMenu = false"
                        >
                          Отмена
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-select
                      v-model="subscription.timetable_id"
                      label="Группа по расписанию"
                      :items="client.timetables"
                      :error-messages="fieldError('timetable_id')"
                      item-value="id"
                      item-text="name"
                      no-data-text="Укажите группы у клиента, чтобы добавить их к абонементу для корректного учета посещений занятий"
                    />
                  </v-col>
                  <v-col
                    v-if="lessonsByDates.length > 0"
                    cols="12"
                    md="12"
                  >
                    Занятий по расписанию в выбранный период: <strong>{{ lessonsByDates.length }}</strong>
                  </v-col>
                  <v-col
                    v-if="lessonsByDates.length > 0"
                    cols="12"
                    md="12"
                  >
                    <v-chip
                      v-for="(lesson, i) in lessonsByDates"
                      :key="i"
                      small
                    >
                      {{ lesson }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-btn
            class="ma-1"
            rounded
            color="secondary"
            min-width="100"
            :disabled="loading"
            @click="saveSubscription()"
          >
            Сохранить
          </v-btn>

          <v-btn
            v-if="subscription.id"
            class="ma-1"
            rounded
            color="error"
            min-width="100"
            :disabled="loading"
            @click="removeSubscription()"
          >
            Удалить
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="refundDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Возврат средств клиенту

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="refundDialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1 text-center">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                ref="form"
                class="mt-5"
                lazy-validation
              >
                <v-text-field
                  v-model="refundValue"
                  required
                  label="Сумма возврата"
                  :error-messages="fieldError('payment')"
                />

                <v-text-field
                  v-model="refundComment"
                  label="Примечание"
                  :error-messages="fieldError('comment')"
                />
              </v-form>
            </v-col>
          </v-row>

          <v-btn
            class="ma-1"
            color="secondary"
            rounded
            min-width="100"
            :disabled="loading"
            @click="submitRefund()"
          >
            Сохранить
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="probDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Назначить пробное занятие для {{ client.name }}

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="probDialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1 text-center">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                ref="form"
                class="mt-5"
                lazy-validation
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="probSubscription.price"
                      required
                      label="Стоимость занятия *"
                      :error-messages="fieldError('price')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-menu
                      ref="startDateMenu"
                      v-model="probDateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="probDate"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          :value="formatDate(probSubscription.start_date)"
                          label="Дата пробного занятия *"
                          :error-messages="fieldError('start_date')"
                          color="secondary"
                          prepend-icon="mdi-calendar-outline"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        v-model="probSubscription.start_date"
                        color="secondary"
                        locale="ru-ru"
                        landscape
                        first-day-of-week="1"
                        scrollable
                        @input="probDateMenu = false"
                      >
                        <v-spacer />

                        <v-btn
                          color="secondary"
                          large
                          @click="probDateMenu = false"
                        >
                          Cancel
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-select
                      v-model="probSubscription.timetable_id"
                      label="Группа по расписанию"
                      :items="client.timetables"
                      :error-messages="fieldError('timetable_id')"
                      item-value="id"
                      item-text="name"
                      no-data-text="Укажите группы у клиента, чтобы добавить их к абонементу для корректного учета посещений занятий"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-btn
            class="ma-1"
            rounded
            color="secondary"
            min-width="100"
            :disabled="loading"
            @click="createProb()"
          >
            Назначить
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmInviteDialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Отправить приглашение в ЛК на email и телефон клиента?
        </v-card-title>

        <v-card-text>
          Клиент получит письмо с кодом активации и СМС на телефон для привязки ученика в своем личном кабинете на сайте
          https://www.poliglotiki.ru/client/
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmInviteDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="sendInvite()"
          >
            Отправить приглашение
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="moveDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Перевод клиента в другую группу {{ client.name }}

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="moveDialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1 text-center">
          <v-row>
            <v-col
              v-if="error"
              cols="12"
              md="12"
            >
              <base-material-alert
                dark
                color="error"
                dismissible
              >
                {{ error }}
              </base-material-alert>
            </v-col>
            <v-col
              v-if="fieldError('client_id').length > 0"
              cols="12"
              md="12"
            >
              <base-material-alert
                dark
                color="error"
                dismissible
              >
                {{ fieldError('client_id') }}
              </base-material-alert>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <p class="text-left text--secondary">
                Данная операция позволяет перевести ученика из одной группы в другую, автоматически создав в новой группе абонемент
                с учетом использованных им занятий в старой группе и внесенной оплаты. Абонемент в новой группе создается с сегодняшней даты.
                Все абонементы в старой группе, активные на текущий момент, а также приобретенные заранее, будут учтены при переводе клиента в новую группу.
                Если в старом абонементе имеется задолженность за абонемент, то она сохранится.
              </p>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="fromTimetableId"
                :error-messages="fieldError('from_timetable_id')"
                label="Первести из группы"
                :items="client.timetables"
                item-value="id"
                item-text="name"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="toTimetableId"
                :error-messages="fieldError('to_timetable_id')"
                label="В группу"
                :items="timetables"
                item-value="id"
                item-text="name"
              />
            </v-col>
          </v-row>
          <v-btn
            class="ma-1"
            rounded
            color="secondary"
            min-width="100"
            :disabled="loading"
            @click="changeTimetable()"
          >
            Продолжить
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
  .break-chip {
    height: auto !important;
    margin-bottom: 1px !important;
    padding-bottom: 3px !important;
    padding-top: 3px !important;
    line-height: 15px !important;
  }

  .v-data-table .v-chip {
    cursor: pointer;
  }
  .archived {
    opacity: 0.5;
    text-decoration: line-through;
  }
  .completed {
    color: green;
  }
  .help-pointer {
    cursor: pointer;
  }
</style>

<script>
  import clientApi from '@/services/ClientsApi'
  import timetableApi from '@/services/TimetablesApi'
  import teacherApi from '@/services/TeachersApi'
  import moment from 'moment'
  import subscriptionsApi from '../services/SubscriptionsApi'
  import coursesApi from '../services/CoursesApi'
  import inviteApi from '../services/InviteApi'
  import CRMStorage from '../services/CRMStorage'
  import { hasRoles, HomeTeacherAdmin } from '../services/jwt.service'
  import actionApi, { ActionState } from '@/services/ActionApi'

  export default {
    filters: {
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('DD.MM.YY HH:mm')
      },
      formatDateFilter: function (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YY')
      },
    },
    props: ['id'],
    data () {
      return {
        error: null,
        nextClientId: null,
        prevClientId: null,
        nextClientName: null,
        prevClientName: null,
        fieldsErrors: [],
        menu3: false,
        trialMenu: false,
        date: '',
        trialDate: '',
        client: {
          id: null,
          name: null,
          phone: null,
          parents: null,
          comment: null,
          address: null,
          email: null,
          office_id: null,
          timetables: [],
          timetableIds: [],
          subscriptions: [],
          status: 1,
          teacher_id: null,
          birthday: null,
          dogovor: null,
          age: null,
          state: null,
          result: null,
          channel: null,
          trial_date: null,
          teacher: {
            name: '',
          },
        },
        loading: true,
        states: [{ id: null, name: ' - не выбрано - ', tag: null }],
        disablesStates: ['awaiting_trial'],
        results: [{ id: null, name: ' - не выбрано - ', tag: null }],
        channels: [{ id: null, name: ' - не выбрано - ', tag: null }],
        teachers: [{ id: null, name: ' - не выбрано - ' }],
        courses: [{ id: null, name: ' - не выбрано - ' }],
        timetables: [{ id: null, name: ' - не выбрано - ' }],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        // Абонементы
        subsHeaders: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: true,
            text: 'С',
            value: 'start_date',
          },
          {
            sortable: true,
            text: 'По',
            value: 'end_date',
          },
          {
            sortable: true,
            text: 'Ст-ть',
            value: 'price',
          },
          {
            sortable: true,
            text: '',
            value: 'status',
          },
          {
            sortable: true,
            text: 'Группа',
            value: 'timetable_id',
          },
        ],
        // История статусов
        stateLogsHeaders: [
          {
            sortable: true,
            text: 'Дата',
            value: 'created',
          },
          {
            sortable: true,
            text: 'Старый',
            value: 'oldstate',
          },
          {
            sortable: true,
            text: 'Новый',
            value: 'newstate',
          },
        ],

        subscriptionDialog: false,
        startDateMenu: false,
        endDateMenu: false,
        startDate: '',
        endDate: '',
        subscription: {
          id: null,
          client: { name: '' },
          start_date: null,
          end_date: null,
          lessons_count: null,
          price: null,
          timetable: null,
          timetable_id: null,
          lessons: [],
          payments: [],
        },

        probDialog: false,
        probDateMenu: false,
        probDate: '',
        probSubscription: {
          id: null,
          client: { name: '' },
          start_date: null,
          end_date: null,
          lessons_count: null,
          price: null,
          timetable: null,
          timetable_id: null,
          lessons: [],
          payments: [],
        },

        templates: [],
        selectedTemplate: null,

        // Платежи
        paymentsHeaders: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: true,
            text: 'Дата',
            value: 'created_date',
          },
          {
            sortable: true,
            text: 'Абон-т',
            value: 'subscription_id',
          },
          {
            sortable: true,
            text: 'Спис',
            value: 'bill',
          },
          {
            sortable: true,
            text: 'Опл',
            value: 'payment',
          },
          {
            sortable: true,
            text: 'Примечание',
            value: 'comment',
          },
        ],
        refundDialog: false,
        refundValue: 0,
        refundComment: 0,
        confirmInviteDialog: false,

        moveDialog: false,
        fromTimetableId: null,
        toTimetableId: null,

        tasksHeaders: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: true,
            text: 'Задача',
            value: 'action',
          },
          {
            sortable: true,
            text: 'Срок',
            value: 'date',
          },
        ],

        tasks: [],
        showArchived: false,
        showCompleted: false,
      }
    },
    computed: {
      tasksFiltered () {
        let items = []
        this.tasks.forEach((item) => {
          if (item.status === 1 && item.completed === 0) items.push(item)
          else if (item.status === 0 && this.showArchived) items.push(item)
          else if (item.completed === 1 && this.showCompleted) items.push(item)
        })
        return items
      },
      ageHint () {
        if (this.client.age) {
          return 'Полных лет: ' + this.client.age
        }
        return ''
      },
      status: {
        get () {
          return this.client.status === 1 || this.client.status === '1'
        },
        set (checked) {
          this.client.status = checked ? 1 : 0
        },
      },
      showTrialDate () {
        if (hasRoles([HomeTeacherAdmin])) return false
        return !!this.client.trial_date || this.client.state === 'awaiting_trial'
      },
      lessonsByDates () {
        let lessons = []
        if (!this.subscription.start_date || !this.subscription.end_date || !this.subscription.timetable_id) {
          return lessons
        }

        let start = new Date(this.subscription.start_date)
        let end = new Date(this.subscription.end_date)
        let timetableId = this.subscription.timetable_id
        for (var i = 0; i < this.timetables.length; i++) {
          if (timetableId === this.timetables[i].id) {
            var timetable = this.timetables[i]
            break
          }
        }

        var loop = new Date(start)
        while (loop <= end) {
          if (moment(loop).isoWeekday() === 1 && timetable.pn === 1) {
            lessons.push('ПН ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_pn.slice(0, -3))
          } else if (moment(loop).isoWeekday() === 2 && timetable.vt === 1) {
            lessons.push('ВТ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_vt.slice(0, -3))
          } else if (moment(loop).isoWeekday() === 3 && timetable.sr === 1) {
            lessons.push('СР ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_sr.slice(0, -3))
          } else if (moment(loop).isoWeekday() === 4 && timetable.ch === 1) {
            lessons.push('ЧТ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_ch.slice(0, -3))
          } else if (moment(loop).isoWeekday() === 5 && timetable.pt === 1) {
            lessons.push('ПТ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_pt.slice(0, -3))
          } else if (moment(loop).isoWeekday() === 6 && timetable.sb === 1) {
            lessons.push('СБ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_sb.slice(0, -3))
          } else if (moment(loop).isoWeekday() === 7 && timetable.vs === 1) {
            lessons.push('ВС ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_vs.slice(0, -3))
          }
          var newDate = loop.setDate(loop.getDate() + 1)
          loop = new Date(newDate)
        }

        return lessons
      },
    },
    /* beforeRouteUpdate (to, from, next) {
      console.log('beforeRouteUpdate')
      // this.id = to.params.id
      next()
    }, */
    mounted () {
      document.title = 'Клиент #' + this.id + ' | ' + process.env.VUE_APP_NAME

      let clientsIds = CRMStorage.getItem('CL_clientsIds', [])
      if (clientsIds && clientsIds.length > 0) {
        let idIndex = clientsIds.indexOf(+this.id)
        if (idIndex + 1 <= clientsIds.length - 1) this.nextClientId = clientsIds[idIndex + 1]
        if (idIndex - 1 >= 0) this.prevClientId = clientsIds[idIndex - 1]
      }

      coursesApi
        .fetchCourses()
        .then(response => {
          this.courses = this.courses.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      teacherApi.fetchActiveTeachers()
        .then(response => {
          this.teachers = this.teachers.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      clientApi
        .fetchClientStates()
        .then(response => {
          this.states = this.states.concat(response)
          if (!hasRoles([HomeTeacherAdmin])) {
            this.states.forEach(function (item) {
              if (item.tag === 'awaiting_trial') item.disabled = true
            })
          }
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      clientApi
        .fetchClientResults()
        .then(response => {
          this.results = this.results.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      clientApi
        .fetchClientChannels()
        .then(response => {
          this.channels = this.channels.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      timetableApi
        .fetchActiveTimetables()
        .then(response => {
          this.timetables = response
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      this.fetchClient()

      if (this.nextClientId) {
        clientApi
          .fetchClientName(this.nextClientId)
          .then(response => {
            this.nextClientName = this.clientName(response)
          })
          .catch(error => {
            this.error = this.pretty(error.response.data)
          })
      }

      if (this.prevClientId) {
        clientApi
          .fetchClientName(this.prevClientId)
          .then(response => {
            this.prevClientName = this.clientName(response)
          })
          .catch(error => {
            this.error = this.pretty(error.response.data)
          })
      }
    },
    methods: {
      back () {
        this.$router.push('/')
      },

      singleSubscription (item) {
        this.$router.push('/subscription/' + item.id)
      },

      singleTask (item) {
        this.$router.push('/action/' + item.id)
      },

      singleSubscriptionByPayment (item) {
        if (item.subscription_id) {
          this.$router.push('/subscription/' + item.subscription_id)
        }
      },

      update () {
        if (this.client.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          clientApi
            .updateClient(this.client)
            .then(response => {
              this.client = response
              if (this.client.timetableIds) {
                this.client.timetableIds = this.client.timetableIds.map(function (item) {
                  return parseInt(item, 10)
                })
              }
              this.loading = false
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        }
      },
      archive () {
        this.client.status = 0
        this.update()
      },
      unarchive () {
        this.client.status = 1
        this.update()
      },

      createAction () {
        this.$router.push('/action/create?client=' + this.client.id)
      },

      createSubscription () {
        this.loading = true
        subscriptionsApi
          .fetchSubscriptionTemplates()
          .then(response => {
            this.templates = response
            this.subscription = {}
            this.fieldsErrors = []
            this.subscription.student_id = this.client.id
            this.subscriptionDialog = true
            if (this.client.timetableIds && this.client.timetableIds.length === 1) {
              this.subscription.timetable_id = Number(this.client.timetableIds[0])
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = this.pretty(error.response.data)
          })
      },

      saveSubscription () {
        this.loading = true
        if (this.subscription.id) {
          this.error = null
          this.fieldsErrors = []
          subscriptionsApi
            .updateSubscription(this.subscription)
            .then(response => {
              this.subscription = response
              this.loading = false
              this.subscriptionDialog = false
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        } else {
          subscriptionsApi
            .createSubscription(this.subscription)
            .then(response => {
              this.subscription = response
              this.subscriptionDialog = false
              if (this.subscription.price > 0) {
                let client = this.subscription.client
                client.state = 'success'
                clientApi
                  .updateClient(client)
                  .then(response => {
                    this.fetchClient()
                  })
                  .catch(error => {
                    this.error = this.pretty(error.response.data)
                  })
              } else {
                this.fetchClient()
              }
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        }
      },

      fetchClient () {
        actionApi
          .fetchClientsActions(this.id)
          .then(response => {
            this.tasks = response
          })
          .catch(error => {
            this.error = this.pretty(error.response.data)
          })

        clientApi
          .fetchClient(this.id)
          .then(response => {
            this.client = response
            document.title = this.client.name + ' | #' + this.id + ' | ' + process.env.VUE_APP_NAME
            if (this.client.timetableIds) {
              this.client.timetableIds = this.client.timetableIds.map(function (item) {
                return parseInt(item, 10)
              })
              // Для корректного отображения архивной группы
              for (let ttId of this.client.timetableIds) {
                const idExists = id => this.timetables.some(({ id }) => id === ttId)
                if (!idExists(ttId)) {
                  timetableApi.fetchTimetable(ttId)
                    .then(response => {
                      this.timetables.unshift(response)
                    })
                    .catch(error => {
                      this.error = this.pretty(error.response.data)
                    })
                }
              }
            }
            this.loading = false
          })
          .catch(error => {
            this.error = this.pretty(error.response.data)
          })
      },

      createRefund () {
        this.refundValue = null
        this.refundComment = null
        this.refundDialog = true
      },

      submitRefund () {
        this.loading = true
        this.refundDialog = false

        let payment = {}
        payment.bill = 0
        payment.payment = -Math.abs(this.refundValue)
        payment.client_id = this.client.id
        payment.comment = this.refundComment

        subscriptionsApi
          .createSubscriptionPayment(payment)
          .then(response => {
            this.fetchClient()
          })
          .catch(error => {
            this.error = this.pretty(error.response.data)
            this.loading = false
          })
      },

      onTemplateChange (event) {
        if (this.selectedTemplate && this.subscription) {
          this.subscription.price = this.selectedTemplate.price
          if (this.selectedTemplate.lessons_count) {
            this.subscription.lessons_count = this.selectedTemplate.lessons_count
          }
        }
      },

      onStateChange (event) {
        if (this.client.state && this.client.state !== 'reject') {
          this.client.result = null
        }
      },

      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },

      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },

      singleClient (clientId) {
        this.$router.push('/client/' + clientId)
      },

      createProb () {
        this.probSubscription.student_id = this.client.id
        this.probSubscription.end_date = this.probSubscription.start_date
        this.probSubscription.lessons_count = 1
        subscriptionsApi
          .createSubscription(this.probSubscription)
          .then(response => {
            this.probSubscription = response
            this.probDialog = false
            let client = this.probSubscription.client
            client.state = 'awaiting_trial'
            client.trial_date = this.probSubscription.start_date
            clientApi
              .updateClient(client)
              .then(response => {
                this.fetchClient()
              })
              .catch(error => {
                this.error = this.pretty(error.response.data)
              })
          })
          .catch(error => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.fieldsErrors = data
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
          })
      },

      toSubscriptionsList () {
        return process.env.VUE_APP_BASE_URL + '/subscriptions/' + this.client.id
      },

      getStatusText (item) {
        let status = ''
        let paymentText = ''
        let lessonsText = ''

        if (item.status === 1) status = '[✔️Актив] '
        if (item.status === -1) status = '[✖️Истек] '
        if (item.status === -2) status = '[↩️Возрат] '

        if (item.balance < 0) {
          paymentText = 'Зад-ть: ' + Math.abs(item.balance) + '. '
        } else if (item.balance > 0) {
          paymentText = 'Перепл.: ' + item.balance + '. '
        } else {
          paymentText = 'Зад-ти нет. '
        }

        let factLessons = item.lessons.length

        if (factLessons > 0 && item.lessons_count > 0 && factLessons > item.lessons_count) {
          lessonsText = 'Перерасход занятий: ' + factLessons + '/' + item.lessons_count
        } else if (factLessons > 0 && item.lessons_count > 0) {
          lessonsText = 'Посещено: ' + factLessons + '/' + item.lessons_count
        } else if (factLessons > 0) {
          lessonsText = 'Посещено: ' + factLessons
        } else if (item.lessons_count > 0) {
          lessonsText = 'Посещено: 0/' + item.lessons_count
        }

        return status + paymentText + lessonsText
      },

      getStatusColor (item) {
        if (item.balance < 0) {
          return 'error'
        } else if (item.balance > 0) {
          return 'warning'
        } else if (item.balance === 0) {
          let factLessons = item.lessons.length
          if (factLessons > 0 && item.lessons_count > 0 && factLessons > item.lessons_count) {
            return 'error'
          }

          if (item.status === 1) return 'success'
          if (item.status === -1) return 'gray'
          if (item.status === -2) return 'info'
        }
        return 'gray'
      },

      getActionStateColor (action) {
        let state = actionApi.getActionState(action)
        if (ActionState.NO_DATE === state) {
          return 'yellow'
        } else if (ActionState.EXPIRED === state) {
          return 'error'
        } else if (ActionState.TODAY === state) {
          return 'primary'
        } else if (ActionState.TOMORROW === state) {
          return 'success'
        } else if (ActionState.LATER === state) {
          return 'light-green'
        } else if (ActionState.COMPLETED === state) {
          return 'green'
        }
        return 'gray'
      },

      sendInvite () {
        this.confirmInviteDialog = false
        this.loading = true
        inviteApi
          .sendInvite(this.client.email, this.client.phone, this.client.id)
          .then(response => {
            this.loading = false
          })
          .catch(error => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.fieldsErrors = data
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
          })
      },

      homeTeacherAdmin () {
        return hasRoles([HomeTeacherAdmin])
      },

      clientNameBreadcrumb () {
        if (!this.client) return ''
        if (!this.client.name && !this.client.parents) return '#' + this.client.id
        return this.client.name ? this.client.name : this.client.parents
      },

      clientName (client) {
        if (!client) return ''
        if (!client.name && !client.parents) return '#' + client.id
        return (client.name ? client.name : client.parents) + ' #' + client.id
      },

      changeTimetable () {
        timetableApi
          .changeTimetable(this.fromTimetableId, this.toTimetableId, this.client.id)
          .then(response => {
            this.loading = false
            this.moveDialog = false
            this.fetchClient()
          })
          .catch(error => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.fieldsErrors = data
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
          })
      },

      formatActionDate (date, time) {
        if (!date) return null
        if (date && time) return moment(date + ' ' + time).format('DD.MM.YY HH:mm')
        return moment(date).format('DD.MM.YY')
      },
    },
  }
</script>
