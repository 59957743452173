import axios from 'axios'

export default {
  sendInvite (email, phone, id) {
    return axios.post('activation-invites/invite', { email: email, phone: phone, crm_id: id }).then(response => {
      return response.data
    })
  },

  sendAlfaInvite (emails, phones, id) {
    return axios.post('activation-invites/invite', { email: emails, phone: phones, alfa_crm_id: id }).then(response => {
      return response.data
    })
  },
}
