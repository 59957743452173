import axios from 'axios'
import moment from 'moment'

export const ActionState = {
  ARCHIVE: 'ARCHIVE',
  COMPLETED: 'COMPLETED',
  NO_DATE: 'NO_DATE',
  EXPIRED: 'EXPIRED',
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
  LATER: 'LATER',
  UNKNOWN: 'UNKNOWN',
}

export default {
  updateAction (action) {
    return axios.put('actions/' + action.id, action).then(response => {
      return response.data
    })
  },

  createAction (action) {
    return axios.post('actions', action).then(response => {
      return response.data
    })
  },

  deleteAction (id) {
    return axios.delete('actions/' + id).then(response => {
      return response.data
    })
  },

  fetchAction (id) {
    return axios.get('actions/' + id + '?expand=client,teacher')
      .then(response => {
        return response.data
      })
  },

  fetchActions () {
    return axios.get('actions').then(response => {
      return response.data
    })
  },

  fetchActiveActions () {
    return axios.get('actions?completed=0&status=1&expand=client,teacher').then(response => {
      return response.data
    })
  },

  getActiveActionsCount () {
    return axios.get('actions/get-actual-count').then(response => {
      return response.data
    })
  },

  fetchClientsActions (clientId) {
    return axios.get('actions?client_id=' + clientId).then(response => {
      return response.data
    })
  },

  fetchTeachersActions (teacherId) {
    return axios.get('actions?completed=0&status=1&teacher_id=' + teacherId).then(response => {
      return response.data
    })
  },

  getActionState (item) {
    if (item.status === 0) return ActionState.ARCHIVE
    if (item.completed === 1) return ActionState.COMPLETED

    let today = moment()
    let tomorrow = moment().add(1, 'days')

    if (!item.date) {
      return ActionState.NO_DATE
    } else if (item.date && item.time && moment(item.date + ' ' + item.time).isBefore(today, 'minute')) {
      return ActionState.EXPIRED
    } else if (moment(item.date).isBefore(today, 'day')) {
      return ActionState.EXPIRED
    } else if (today.isSame(moment(item.date), 'day')) {
      return ActionState.TODAY
    } else if (tomorrow.isSame(moment(item.date), 'day')) {
      return ActionState.TOMORROW
    } else if (moment(item.date).isAfter(tomorrow, 'day')) {
      return ActionState.LATER
    }

    return ActionState.UNKNOWN
  },
}
