var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "client-forms", fluid: "", tag: "section" } },
    [
      _vm.loading
        ? _c("v-container", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    size: 150,
                    width: 8,
                    color: "green"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-row",
        [
          !_vm.loading
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "space-between" } },
                    [
                      !_vm.loading
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mr-auto mt-0 mb-0 pt-0 pb-0",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("v-breadcrumbs", {
                                staticClass: "mt-0 mb-0 pt-0 pb-0",
                                attrs: {
                                  items: [
                                    { text: "Ваш центр", to: "/" },
                                    { text: "Клиенты", to: "/clients" },
                                    { text: _vm.clientNameBreadcrumb() }
                                  ],
                                  large: ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      !_vm.loading && (_vm.prevClientId || _vm.nextClientId)
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mr-auto mt-0 mb-0 pt-0 pb-0",
                              attrs: { cols: "auto" }
                            },
                            [
                              _vm.prevClientId
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.singleClient(
                                            _vm.prevClientId
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-left")
                                      ]),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.prevClientName) +
                                          "\n          "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-btn", { attrs: { small: "" } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.clientName(_vm.client)) +
                                    "\n          "
                                )
                              ]),
                              _vm.nextClientId
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.singleClient(
                                            _vm.nextClientId
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.nextClientName) +
                                          "\n            "
                                      ),
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: { color: "success", icon: "mdi-badge-account" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "after-heading",
                            fn: function() {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { right: "", "max-width": "50%" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "card-title font-weight-light"
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                Данные клиента\n                "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "help-pointer"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  mdi-help-circle-outline\n                "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1182325781
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        'Карточка содержит полную информацию о клиенте, его абонементах, оплатах, остатке на счете. Если счет клиента стал положительным из-за возврата услуг (абонементов), то эти средства можно использовать для оплаты другого абонемента или вывести со счёта и вернуть клиенту - для этого нажмите "Сделать возврат". Не забывайте менять статус клиента!'
                                      )
                                    ])
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        407950870
                      )
                    },
                    [
                      !_vm.client.status
                        ? _c(
                            "base-material-alert",
                            {
                              attrs: {
                                color: "warning",
                                dark: "",
                                icon: "mdi-zip-disk"
                              }
                            },
                            [_vm._v("\n          Архивный клиент!\n        ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "mt-5",
                          attrs: { "lazy-validation": "" }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Статус",
                                      items: _vm.states,
                                      "error-messages": _vm.fieldError("state"),
                                      "item-value": "tag",
                                      "item-text": "name"
                                    },
                                    on: { change: _vm.onStateChange },
                                    model: {
                                      value: _vm.client.state,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "state", $$v)
                                      },
                                      expression: "client.state"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "result"
                                      ),
                                      label: "Причина отказа",
                                      items: _vm.results,
                                      "item-value": "tag",
                                      "item-text": "name"
                                    },
                                    model: {
                                      value: _vm.client.result,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "result", $$v)
                                      },
                                      expression: "client.result"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "channel"
                                      ),
                                      label: "Канал",
                                      items: _vm.channels,
                                      "item-value": "tag",
                                      "item-text": "name"
                                    },
                                    model: {
                                      value: _vm.client.channel,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "channel", $$v)
                                      },
                                      expression: "client.channel"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.showTrialDate
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          value: _vm.formatDate(
                                            _vm.client.trial_date
                                          ),
                                          label: "Дата пробного занятия",
                                          "error-messages": _vm.fieldError(
                                            "trial_date"
                                          ),
                                          color: "secondary",
                                          "prepend-icon": "mdi-calendar-outline"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "ФИО родителя/опекуна",
                                      "error-messages": _vm.fieldError(
                                        "parents"
                                      )
                                    },
                                    model: {
                                      value: _vm.client.parents,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "parents", $$v)
                                      },
                                      expression: "client.parents"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "11", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Телефон",
                                      "error-messages": _vm.fieldError("phone"),
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.client.phone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "phone", $$v)
                                      },
                                      expression: "client.phone"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "1", md: "1" } },
                                [
                                  _vm.client.phone
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-0 pr-0",
                                          attrs: {
                                            title:
                                              "Отправить клиенту приглашение и код для доступа в ЛК"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.confirmInviteDialog = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                mdi-card-account-mail\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "11", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "error-messages": _vm.fieldError("email"),
                                      label: "E-mail"
                                    },
                                    model: {
                                      value: _vm.client.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "email", $$v)
                                      },
                                      expression: "client.email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "1", md: "1" } },
                                [
                                  _vm.client.email
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-0 pr-0",
                                          attrs: {
                                            title:
                                              "Отправить клиенту приглашение и код для доступа в ЛК"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.confirmInviteDialog = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                mdi-card-account-mail\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-textarea", {
                            attrs: {
                              "error-messages": _vm.fieldError("comment"),
                              rows: "1",
                              "auto-grow": "",
                              label: "Комментарий"
                            },
                            model: {
                              value: _vm.client.comment,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "comment", $$v)
                              },
                              expression: "client.comment"
                            }
                          }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "ФИО ребенка",
                                      "error-messages": _vm.fieldError("name")
                                    },
                                    model: {
                                      value: _vm.client.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "name", $$v)
                                      },
                                      expression: "client.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "menu2",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "return-value": _vm.date,
                                        transition: "scale-transition",
                                        "min-width": "290px",
                                        "offset-y": ""
                                      },
                                      on: {
                                        "update:returnValue": function($event) {
                                          _vm.date = $event
                                        },
                                        "update:return-value": function(
                                          $event
                                        ) {
                                          _vm.date = $event
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        readonly: "",
                                                        value: _vm.formatDate(
                                                          _vm.client.birthday
                                                        ),
                                                        label:
                                                          "День рождения ребенка",
                                                        "error-messages": _vm.fieldError(
                                                          "birthday"
                                                        ),
                                                        color: "secondary",
                                                        "prepend-icon":
                                                          "mdi-calendar-outline",
                                                        messages: _vm.ageHint
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2337359441
                                      ),
                                      model: {
                                        value: _vm.menu3,
                                        callback: function($$v) {
                                          _vm.menu3 = $$v
                                        },
                                        expression: "menu3"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-date-picker",
                                        {
                                          attrs: {
                                            color: "secondary",
                                            locale: "ru-ru",
                                            landscape: "",
                                            scrollable: "",
                                            "first-day-of-week": "1"
                                          },
                                          on: {
                                            input: function($event) {
                                              _vm.menu3 = false
                                            }
                                          },
                                          model: {
                                            value: _vm.client.birthday,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.client,
                                                "birthday",
                                                $$v
                                              )
                                            },
                                            expression: "client.birthday"
                                          }
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                large: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.menu3 = false
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Cancel\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "dogovor"
                                      ),
                                      label: "Номер договора"
                                    },
                                    model: {
                                      value: _vm.client.dogovor,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "dogovor", $$v)
                                      },
                                      expression: "client.dogovor"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "8" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "address"
                                      ),
                                      label: "Адрес проживания"
                                    },
                                    model: {
                                      value: _vm.client.address,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "address", $$v)
                                      },
                                      expression: "client.address"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "teacher_id"
                                      ),
                                      label: "Закрепленный педагог",
                                      "item-value": "id",
                                      "item-text": "name",
                                      items: _vm.teachers
                                    },
                                    model: {
                                      value: _vm.client.teacher_id,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "teacher_id", $$v)
                                      },
                                      expression: "client.teacher_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "8" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "timetableIds"
                                      ),
                                      color: "secondary",
                                      "item-color": "secondary",
                                      label: "Группы по расписанию",
                                      "item-value": "id",
                                      "item-text": "name",
                                      multiple: "",
                                      items: _vm.timetables
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function(ref) {
                                            var attrs = ref.attrs
                                            var item = ref.item
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-list-item",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "mx-3 mb-2 v-sheet",
                                                      attrs: {
                                                        "active-class":
                                                          "secondary elevation-4 white--text",
                                                        elevation: "0"
                                                      }
                                                    },
                                                    "v-list-item",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", {
                                                        attrs: {
                                                          "item-value":
                                                            "item.id",
                                                          "return-object": ""
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.name
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-scale-transition",
                                                    [
                                                      attrs.inputValue
                                                        ? _c(
                                                            "v-list-item-icon",
                                                            {
                                                              staticClass:
                                                                "my-3"
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-check"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1708393508
                                    ),
                                    model: {
                                      value: _vm.client.timetableIds,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.client,
                                          "timetableIds",
                                          $$v
                                        )
                                      },
                                      expression: "client.timetableIds"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "error-messages": _vm.fieldError(
                                        "course_id"
                                      ),
                                      label: "Интересующий курс",
                                      "item-value": "id",
                                      "item-text": "name",
                                      items: _vm.courses
                                    },
                                    model: {
                                      value: _vm.client.course_id,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "course_id", $$v)
                                      },
                                      expression: "client.course_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "pl-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "success",
                                "min-width": "100",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.update()
                                }
                              }
                            },
                            [_vm._v("\n            Сохранить\n          ")]
                          ),
                          _vm.client.status
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "error",
                                    "min-width": "100",
                                    title: "Переместить клиента в архив",
                                    disabled: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.archive()
                                    }
                                  }
                                },
                                [_vm._v("\n            В архив\n          ")]
                              )
                            : _vm._e(),
                          !_vm.client.status
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "error",
                                    "min-width": "100",
                                    disabled: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.unarchive()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Вернуть из архива\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          !_vm.homeTeacherAdmin() &&
                          _vm.client.status &&
                          _vm.client.state != "awaiting_trial"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "info", "min-width": "100" },
                                  on: {
                                    click: function($event) {
                                      _vm.probDialog = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Назначить пробное\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.client.status &&
                          _vm.client.timetableIds &&
                          _vm.client.timetableIds.length > 0
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "teal", "min-width": "100" },
                                  on: {
                                    click: function($event) {
                                      _vm.moveDialog = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Сменить группу\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: { color: "teal", icon: "mdi-list-status" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "after-heading",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "card-title font-weight-light"
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", [_vm._v("Задачи")]),
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-switch", {
                                              staticClass: "mt-0 mb-0",
                                              attrs: {
                                                label: "Показать архивные"
                                              },
                                              model: {
                                                value: _vm.showArchived,
                                                callback: function($$v) {
                                                  _vm.showArchived = $$v
                                                },
                                                expression: "showArchived"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c("v-switch", {
                                              staticClass: "mt-0 mb-0",
                                              attrs: {
                                                label: "Показать выполненные"
                                              },
                                              model: {
                                                value: _vm.showCompleted,
                                                callback: function($$v) {
                                                  _vm.showCompleted = $$v
                                                },
                                                expression: "showCompleted"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2574737526
                      )
                    },
                    [
                      _c("v-data-table", {
                        attrs: {
                          loading: _vm.loading,
                          "loading-text": "Загрузка...",
                          headers: _vm.tasksHeaders,
                          items: _vm.tasksFiltered,
                          "sort-by": ["date", "time"],
                          "sort-desc": [false, false]
                        },
                        on: { "click:row": _vm.singleTask },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function(ref) {
                                var items = ref.items
                                return [
                                  _c(
                                    "tbody",
                                    _vm._l(items, function(item) {
                                      return _c(
                                        "tr",
                                        {
                                          key: item.id,
                                          class: {
                                            archived: item.status === 0,
                                            completed: item.completed === 1
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.singleTask(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("td", [_vm._v(_vm._s(item.id))]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(_vm._s(item.action))
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-block text--disabled"
                                              },
                                              [_vm._v(_vm._s(item.comment))]
                                            )
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              item.date
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        color: _vm.getActionStateColor(
                                                          item
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.formatActionDate(
                                                              item.date,
                                                              item.time
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2333895849
                        )
                      }),
                      _c(
                        "v-card-actions",
                        { staticClass: "pl-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "secondary",
                                "min-width": "100",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.createAction()
                                }
                              }
                            },
                            [_vm._v("\n            Создать задачу\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "orange",
                        icon: "mdi-account-convert",
                        title: "История статусов"
                      }
                    },
                    [
                      _c("v-data-table", {
                        attrs: {
                          loading: _vm.loading,
                          "loading-text": "Загрузка...",
                          headers: _vm.stateLogsHeaders,
                          items: _vm.client.stateLogs
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.created",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("formatDateFromUnixTime")(
                                          item.created
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.oldstate",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.oldStateRel
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.oldStateRel.name))
                                      ])
                                    : _c("span", [_vm._v("—")])
                                ]
                              }
                            },
                            {
                              key: "item.newstate",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.newStateRel
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.newStateRel.name))
                                      ])
                                    : _c("span", [_vm._v("—")])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3037072435
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "indigo",
                        icon: "mdi-cash",
                        title: "Абонементы"
                      }
                    },
                    [
                      _c("v-data-table", {
                        attrs: {
                          loading: _vm.loading,
                          "loading-text": "Загрузка абонеметов...",
                          headers: _vm.subsHeaders,
                          "sort-by": ["id"],
                          "sort-desc": [true],
                          items: _vm.client.subscriptions
                        },
                        on: { "click:row": _vm.singleSubscription },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.timetable_id",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.timetable
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "/timetable/" + item.timetable_id,
                                            title:
                                              "Открыть группу в новой вкладке",
                                            target: "_blank"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "break-chip",
                                              attrs: {
                                                color: "pink lighten-5",
                                                "x-small": ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(item.timetable.name)
                                              ),
                                              _c(
                                                "v-icon",
                                                { attrs: { right: "" } },
                                                [_vm._v("mdi-open-in-new")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-chip",
                                        {
                                          staticClass: "break-chip",
                                          attrs: {
                                            color: "pink lighten-5",
                                            "x-small": "",
                                            title:
                                              "Группа указанная в данном абонементе была удалена"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              Удаленная группа #" +
                                              _vm._s(item.timetable_id) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                ]
                              }
                            },
                            {
                              key: "item.start_date",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("formatDateFilter")(
                                          item.start_date
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.end_date",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("formatDateFilter")(
                                          item.end_date
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.status",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "break-chip",
                                      attrs: {
                                        title: "Перейти к абонементу",
                                        "x-small": "",
                                        color: _vm.getStatusColor(item)
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.getStatusText(item)) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3953253654
                        )
                      }),
                      _c(
                        "v-card-actions",
                        { staticClass: "pl-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "info",
                                "min-width": "100",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.createSubscription()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            Создать абонемент\n          "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "teal",
                                "min-width": "100",
                                target: "_blank",
                                href: _vm.toSubscriptionsList()
                              }
                            },
                            [
                              _vm._v(
                                "\n            Показать на календаре\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "primary",
                        icon: "mdi-wallet",
                        title: "Платежи и списания"
                      }
                    },
                    [
                      _c(
                        "base-material-alert",
                        {
                          staticClass: "mb-0",
                          attrs: {
                            color: "warning",
                            dark: "",
                            icon: "mdi-alert-box"
                          }
                        },
                        [
                          _c("strong", [_vm._v("Счет*:")]),
                          _c(
                            "v-chip",
                            { staticClass: "ml-2", attrs: { color: "red" } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.client.balance) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c("v-divider", { staticClass: "my-4" }),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "\n            * Отрицательные значения свидетельствуют о задолженности, положительные — о переплате.\n          "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-data-table", {
                        attrs: {
                          loading: _vm.loading,
                          "loading-text": "Загрузка...",
                          headers: _vm.paymentsHeaders,
                          items: _vm.client.paymentsAndBills,
                          "sort-by": ["id"],
                          "sort-desc": [true]
                        },
                        on: { "click:row": _vm.singleSubscriptionByPayment },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.subscription_id",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    "\n            #" +
                                      _vm._s(item.subscription_id) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.created_date",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("formatDateFromUnixTime")(
                                          item.created_date
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2772583085
                        )
                      }),
                      _c("p", { staticClass: "body-2 text--disabled pt-4" }, [
                        _vm._v(
                          "\n          Для возврата денег выберите абонемент и оформите возврат на карточке конкретного абонемента.\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.subscriptionDialog,
            callback: function($$v) {
              _vm.subscriptionDialog = $$v
            },
            expression: "subscriptionDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v(
                    "\n        Абонемент " +
                      _vm._s(_vm.client.name) +
                      "\n\n        "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.subscriptionDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1 text-center" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-5",
                              attrs: { "lazy-validation": "" }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Шаблон абонемента",
                                          items: _vm.templates,
                                          "item-value": "id",
                                          "item-text": "name",
                                          "return-object": ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.onTemplateChange($event)
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedTemplate,
                                          callback: function($$v) {
                                            _vm.selectedTemplate = $$v
                                          },
                                          expression: "selectedTemplate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Стоимость *",
                                          "error-messages": _vm.fieldError(
                                            "price"
                                          )
                                        },
                                        model: {
                                          value: _vm.subscription.price,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.subscription,
                                              "price",
                                              $$v
                                            )
                                          },
                                          expression: "subscription.price"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Число занятий",
                                          "error-messages": _vm.fieldError(
                                            "lessons_count"
                                          )
                                        },
                                        model: {
                                          value: _vm.subscription.lessons_count,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.subscription,
                                              "lessons_count",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "subscription.lessons_count"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "startDateMenu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "return-value": _vm.startDate,
                                            transition: "scale-transition",
                                            "min-width": "290px",
                                            "offset-y": ""
                                          },
                                          on: {
                                            "update:returnValue": function(
                                              $event
                                            ) {
                                              _vm.startDate = $event
                                            },
                                            "update:return-value": function(
                                              $event
                                            ) {
                                              _vm.startDate = $event
                                            }
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          readonly: "",
                                                          value: _vm.formatDate(
                                                            _vm.subscription
                                                              .start_date
                                                          ),
                                                          label: "с *",
                                                          "error-messages": _vm.fieldError(
                                                            "start_date"
                                                          ),
                                                          color: "secondary",
                                                          "prepend-icon":
                                                            "mdi-calendar-outline",
                                                          messages:
                                                            "Дата начала действия абонемента"
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.startDateMenu,
                                            callback: function($$v) {
                                              _vm.startDateMenu = $$v
                                            },
                                            expression: "startDateMenu"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                locale: "ru-ru",
                                                landscape: "",
                                                "first-day-of-week": "1",
                                                scrollable: ""
                                              },
                                              on: {
                                                input: function($event) {
                                                  _vm.startDateMenu = false
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.subscription.start_date,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.subscription,
                                                    "start_date",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "subscription.start_date"
                                              }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    large: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.startDateMenu = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Cancel\n                      "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "endDateMenu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "return-value": _vm.endDate,
                                            transition: "scale-transition",
                                            "min-width": "290px",
                                            "offset-y": ""
                                          },
                                          on: {
                                            "update:returnValue": function(
                                              $event
                                            ) {
                                              _vm.endDate = $event
                                            },
                                            "update:return-value": function(
                                              $event
                                            ) {
                                              _vm.endDate = $event
                                            }
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          readonly: "",
                                                          value: _vm.formatDate(
                                                            _vm.subscription
                                                              .end_date
                                                          ),
                                                          label: "по *",
                                                          "error-messages": _vm.fieldError(
                                                            "end_date"
                                                          ),
                                                          color: "secondary",
                                                          "prepend-icon":
                                                            "mdi-calendar-outline",
                                                          messages:
                                                            "Дата окончания действия абонемента (включительно)"
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.endDateMenu,
                                            callback: function($$v) {
                                              _vm.endDateMenu = $$v
                                            },
                                            expression: "endDateMenu"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                locale: "ru-ru",
                                                landscape: "",
                                                scrollable: "",
                                                "first-day-of-week": "1"
                                              },
                                              on: {
                                                input: function($event) {
                                                  _vm.endDateMenu = false
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.subscription.end_date,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.subscription,
                                                    "end_date",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "subscription.end_date"
                                              }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    large: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.endDateMenu = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Отмена\n                      "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Группа по расписанию",
                                          items: _vm.client.timetables,
                                          "error-messages": _vm.fieldError(
                                            "timetable_id"
                                          ),
                                          "item-value": "id",
                                          "item-text": "name",
                                          "no-data-text":
                                            "Укажите группы у клиента, чтобы добавить их к абонементу для корректного учета посещений занятий"
                                        },
                                        model: {
                                          value: _vm.subscription.timetable_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.subscription,
                                              "timetable_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "subscription.timetable_id"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.lessonsByDates.length > 0
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _vm._v(
                                            "\n                  Занятий по расписанию в выбранный период: "
                                          ),
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.lessonsByDates.length)
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.lessonsByDates.length > 0
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        _vm._l(_vm.lessonsByDates, function(
                                          lesson,
                                          i
                                        ) {
                                          return _c(
                                            "v-chip",
                                            { key: i, attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(lesson) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      attrs: {
                        rounded: "",
                        color: "secondary",
                        "min-width": "100",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.saveSubscription()
                        }
                      }
                    },
                    [_vm._v("\n          Сохранить\n        ")]
                  ),
                  _vm.subscription.id
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: {
                            rounded: "",
                            color: "error",
                            "min-width": "100",
                            disabled: _vm.loading
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeSubscription()
                            }
                          }
                        },
                        [_vm._v("\n          Удалить\n        ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.refundDialog,
            callback: function($$v) {
              _vm.refundDialog = $$v
            },
            expression: "refundDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v("\n        Возврат средств клиенту\n\n        "),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.refundDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1 text-center" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-5",
                              attrs: { "lazy-validation": "" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Сумма возврата",
                                  "error-messages": _vm.fieldError("payment")
                                },
                                model: {
                                  value: _vm.refundValue,
                                  callback: function($$v) {
                                    _vm.refundValue = $$v
                                  },
                                  expression: "refundValue"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Примечание",
                                  "error-messages": _vm.fieldError("comment")
                                },
                                model: {
                                  value: _vm.refundComment,
                                  callback: function($$v) {
                                    _vm.refundComment = $$v
                                  },
                                  expression: "refundComment"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      attrs: {
                        color: "secondary",
                        rounded: "",
                        "min-width": "100",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.submitRefund()
                        }
                      }
                    },
                    [_vm._v("\n          Сохранить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.probDialog,
            callback: function($$v) {
              _vm.probDialog = $$v
            },
            expression: "probDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v(
                    "\n        Назначить пробное занятие для " +
                      _vm._s(_vm.client.name) +
                      "\n\n        "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.probDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1 text-center" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-5",
                              attrs: { "lazy-validation": "" }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Стоимость занятия *",
                                          "error-messages": _vm.fieldError(
                                            "price"
                                          )
                                        },
                                        model: {
                                          value: _vm.probSubscription.price,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.probSubscription,
                                              "price",
                                              $$v
                                            )
                                          },
                                          expression: "probSubscription.price"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "startDateMenu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "return-value": _vm.probDate,
                                            transition: "scale-transition",
                                            "min-width": "290px",
                                            "offset-y": ""
                                          },
                                          on: {
                                            "update:returnValue": function(
                                              $event
                                            ) {
                                              _vm.probDate = $event
                                            },
                                            "update:return-value": function(
                                              $event
                                            ) {
                                              _vm.probDate = $event
                                            }
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          readonly: "",
                                                          value: _vm.formatDate(
                                                            _vm.probSubscription
                                                              .start_date
                                                          ),
                                                          label:
                                                            "Дата пробного занятия *",
                                                          "error-messages": _vm.fieldError(
                                                            "start_date"
                                                          ),
                                                          color: "secondary",
                                                          "prepend-icon":
                                                            "mdi-calendar-outline"
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.probDateMenu,
                                            callback: function($$v) {
                                              _vm.probDateMenu = $$v
                                            },
                                            expression: "probDateMenu"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                color: "secondary",
                                                locale: "ru-ru",
                                                landscape: "",
                                                "first-day-of-week": "1",
                                                scrollable: ""
                                              },
                                              on: {
                                                input: function($event) {
                                                  _vm.probDateMenu = false
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.probSubscription
                                                    .start_date,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.probSubscription,
                                                    "start_date",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "probSubscription.start_date"
                                              }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    large: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.probDateMenu = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Cancel\n                      "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Группа по расписанию",
                                          items: _vm.client.timetables,
                                          "error-messages": _vm.fieldError(
                                            "timetable_id"
                                          ),
                                          "item-value": "id",
                                          "item-text": "name",
                                          "no-data-text":
                                            "Укажите группы у клиента, чтобы добавить их к абонементу для корректного учета посещений занятий"
                                        },
                                        model: {
                                          value:
                                            _vm.probSubscription.timetable_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.probSubscription,
                                              "timetable_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "probSubscription.timetable_id"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      attrs: {
                        rounded: "",
                        color: "secondary",
                        "min-width": "100",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.createProb()
                        }
                      }
                    },
                    [_vm._v("\n          Назначить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.confirmInviteDialog,
            callback: function($$v) {
              _vm.confirmInviteDialog = $$v
            },
            expression: "confirmInviteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(
                  "\n        Отправить приглашение в ЛК на email и телефон клиента?\n      "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Клиент получит письмо с кодом активации и СМС на телефон для привязки ученика в своем личном кабинете на сайте\n        https://www.poliglotiki.ru/client/\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmInviteDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.sendInvite()
                        }
                      }
                    },
                    [_vm._v("\n          Отправить приглашение\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.moveDialog,
            callback: function($$v) {
              _vm.moveDialog = $$v
            },
            expression: "moveDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v(
                    "\n        Перевод клиента в другую группу " +
                      _vm._s(_vm.client.name) +
                      "\n\n        "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Close" },
                      on: {
                        click: function($event) {
                          _vm.moveDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          mdi-close\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1 text-center" },
                [
                  _c(
                    "v-row",
                    [
                      _vm.error
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c(
                                "base-material-alert",
                                {
                                  attrs: {
                                    dark: "",
                                    color: "error",
                                    dismissible: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.error) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.fieldError("client_id").length > 0
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c(
                                "base-material-alert",
                                {
                                  attrs: {
                                    dark: "",
                                    color: "error",
                                    dismissible: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.fieldError("client_id")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-col", { attrs: { cols: "12", md: "12" } }, [
                        _c("p", { staticClass: "text-left text--secondary" }, [
                          _vm._v(
                            "\n              Данная операция позволяет перевести ученика из одной группы в другую, автоматически создав в новой группе абонемент\n              с учетом использованных им занятий в старой группе и внесенной оплаты. Абонемент в новой группе создается с сегодняшней даты.\n              Все абонементы в старой группе, активные на текущий момент, а также приобретенные заранее, будут учтены при переводе клиента в новую группу.\n              Если в старом абонементе имеется задолженность за абонемент, то она сохранится.\n            "
                          )
                        ])
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              "error-messages": _vm.fieldError(
                                "from_timetable_id"
                              ),
                              label: "Первести из группы",
                              items: _vm.client.timetables,
                              "item-value": "id",
                              "item-text": "name"
                            },
                            model: {
                              value: _vm.fromTimetableId,
                              callback: function($$v) {
                                _vm.fromTimetableId = $$v
                              },
                              expression: "fromTimetableId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              "error-messages": _vm.fieldError(
                                "to_timetable_id"
                              ),
                              label: "В группу",
                              items: _vm.timetables,
                              "item-value": "id",
                              "item-text": "name"
                            },
                            model: {
                              value: _vm.toTimetableId,
                              callback: function($$v) {
                                _vm.toTimetableId = $$v
                              },
                              expression: "toTimetableId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-1",
                      attrs: {
                        rounded: "",
                        color: "secondary",
                        "min-width": "100",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.changeTimetable()
                        }
                      }
                    },
                    [_vm._v("\n          Продолжить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }